@font-face {
    font-family: 'FZQKBYS';
    src: url('/styles/fonts/FZQingKeBenYueSong.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
    font-family: 'FZCS';
    src: url('/styles/fonts/FZCuSong.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}