@import "typography";
@import "layout";
@import "animation";
@import "cursor";

.circle-before::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: -15px;
  top: 10px;
  border-radius: 50%;
  border: 1px solid #000;
  color: #333;
}

.grid line {
  stroke: lightgray;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px;
  font: 13px FZQKBYS;
  border: 0px;
  border-radius: 3px;
  pointer-events: none;
}

#container-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("/assets/bg-b.png");
  opacity: 0.5;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-attachment: fixed;
}

#container-detail::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-image: url("/assets/bg-detail.png");
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.year-scale line {
  stroke: #777;
}

.year-scale path {
  stroke: #777;
}

.detail-ui-year {
  font-weight: bold;
  font-family: FZCS, sans-serif;
  font-size: 2rem;
  color: #fff;
  background-color: #0071BC;
  padding: 2px;
}

.detail-ui-event-heading {
  font-weight: bold;
  font-family: FZCS, sans-serif;
  font-size: 2rem;
  color: #fff;
}